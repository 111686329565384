import 'bootstrap/dist/css/bootstrap.css';
import './src/assets/css/fonts.css';
import './src/assets/css/app.css';
import './src/assets/css/home.css';
import './src/assets/css/company.css';
import './src/assets/css/requirement.css';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import * as ReactGA from 'react-ga';
ReactGA.initialize('UA-59053438-3');
